<template>
  <section class="memoir">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <div class="title"><h1>{{ options.title }}</h1><p><strong>{{ options.tit }}</strong></p></div>
      <div class="memoir-pic"><el-image :src="options.url" style="width: 100%;"></el-image></div>
      <!-- <div class="memoir-pic"><el-image :src="options.footerUrl"></el-image></div> -->
      <!-- <section class="memoir-main">
        <el-row class="memoir-list">
          <el-col :span="12" class="memoir-left">
            <div v-for="(item, index) in options.content" :key="index">
              <div v-if="index % 2 == 0" class="memoir-odd">
                <div class="memoit-time"><strong>{{ item.time }}</strong><span class="memoir-yuan1"></span><span class="memoir-line"></span><span class="memoir-yuan2"></span></div>
                <div v-for="(org, i) in item.content" :key="i" class="memoit-content">
                  <span class="memoir-dian">•</span><section class="memoir-con">{{ org }}</section>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="memoir-right">
            <div v-for="(item, index) in options.content" :key="index">
              <div v-if="index % 2 == 1" class="memoir-even">
                <div class="memoit-time"><span class="memoir-yuan2"></span><span class="memoir-line"></span><span class="memoir-yuan1"></span><strong>{{ item.time }}</strong></div>
                <div v-for="(org, i) in item.content" :key="i" class="memoit-content">
                  <span class="memoir-dian">•</span><section class="memoir-con">{{ org }}</section>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="memoir-center-line"></div>
      </section> -->
    </section>
  </section>
</template>

<script>

import imageBanner from '../../components/ImageBanner.vue'
export default {
  components: {imageBanner},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/关于我们.png'),
        slogan: require('../../assets/images/2023/aboutus/slogan.png'),
      },
      options: {
        title: 'COURSE',
        tit: '旋荣大事记',
        url: require('../../assets/images/2023/aboutus/成长轨迹.png'),
        // footerUrl: require('../../assets/images/aboutus/成长轨迹底图.png'),
        content: [
          {time: 2022, content: [
            '积极参加上海地区的疫情防控，获得街道基层政府组织的表扬',
            '2022年5月，公司的视觉AI精确识别项目全面展开，利用AI图像增强网络、多尺度检测模型等先进技术，有效提高客户的物资管理效率。',
            '2022年6月，“助力飞”现代智慧供应链 SaaS 平台的“项目管理”模块上线，本模块可以清晰地展现各个项目的进展情况，便于各级管理层对项目进行实时跟踪，并且可以根据项目实际情况进行订制。',
            '2022年8月，公司加入上海市电力工程协会，在协会的支持下，公司各个新项目陆续展开。',
            '2022年12月，公司发布《面向中小企业的智慧供应链平台技术规范》系列标准，得到的业内人士的高度认可。',
          ]},
          {time: 2021, content: [
            '公司主导进行了行业标准、团体标准的制订：《国有企业电子商务办公和MRO类物资通用管理要求》',
            '上海旋荣科技股份有限公司党支部被南京东路街道评为“先进基层党组织”',
            '河南郑州爆发特大暴雨灾害，参与国网上海市电力公司在郑州地区电力抢修的物资供应工作',
            '助力飞5G上线：新增电子商城板块，打通了内部管理和电商平台，既给电商平台赋予一个强大的后台，又给共享库存开辟了一个新的业务出口，使得助力飞商业模式更加完善。同时新增生产制造板块和项目管理板块，使得一体化的管理范围的到更大的扩展，大大增强系统的实用性和效率',
            '变电站智能辅控平台经过多年研发后正式面世，参照最新标准研发，且通过CNAS实验室认证',
            '2021年5月，公司全体员工搬迁至自有资金购置的新办公室，地址为：上海市延安东路 175 号旺角广场1301-1303 室。',
            '2021年10月，公司召开的 2021 年第三次临时股东大会审议通过了2021年半年度权益分派方案，公司以总股本 2435 万股为基数，向全体股东每10股送红股 3.6 股，并且每 10 股转增 1 股，公司股本增加至3555.1万元。',
            '2021年12月，公司的全资子公司“上海旋胜科技有限公司”取得了高新技术企业证书。',
            '2022年12月，公司中标国网上海市电力公司2023年第一次电商化项目，累计中标金额3193万元，占预成交份额4.5%，份额排名全市前三，得到主要客户持续的信任与认可。',
          ]},
          {time: 2020, content: [
            '年初疫情肆虐，筹集了数千个口罩，捐赠给南京东路街道、南京东路派出所和武汉幸福社区工作人员等单位，藉此向所有抗疫一线的工作人员致敬。',
            '核心员工认定，并进行首轮的员工股权激励',
            '升级为新三板创新层企业',
            '微环境整治系统升级为：微环境（温湿度、积水）状态监测平台，配套的系列自研产品有：双组分有机硅电柜专用防潮胶，半导体冷凝除湿系统、LORA微环境嵌入式监控系统，气体浓度、水分嵌入式监控系统。主要应用于特高压的环境和设备中。',
            '助力飞4G在同行业中进行推广，取得良好的效果，同时根据用户的新增需求，不断丰富助力飞的功能',
          ]},
          {time: 2019, content: [
            '深圳分公司成立，拓展南方市场业务，对接深圳研发的合作伙伴',
            '山东分公司成立，拓展山东市场',
            '自主研发电力设备微环境整治系统上线，并迅速打开市场',
            '助力飞4G上线：“企业版拼多多”模式和基于共享商城模式上线，进一步丰富助力飞的供应链上下游赋能功能并对商业模式进行进一步验证',
          ]},
          {time: 2018, content: [
            '设立全资子公司上海旋胜科技有限公司，作为公司的独立研发中心而设立',
            '变电宝产品上线，旨在提高变电站范围内设备的运行、检修的一体化管理效率',
            '南京分部办公地址搬迁，南京运维中心正式成立',
            '上海旋荣科技股份有限公司党支部成立',
            '上海旋荣科技股份有限公司工会成立',
            '参与军网商城（联勤保障中心）的项目履约',
            '助力飞3G：根据业务的升级，不断升级和完善助力飞的功能',
            '加大有关微环境（温湿度、积水）状态监测平台的产品研发',
          ]},
          {time: 2017, content: [
            '南京分部正式成立，作为公司的辅助研发中心',
            '成立产品部，开始中高端产品自主研发',
            '由产品部主导部分仪器仪表、工器具产品的OEM贴牌生产',
            '票秘书电子发票开票APP上线',
            '助力飞3G上线：并升级为SaaS架构，并着力完善运营协同板块，初步实现以销定采，销售机会管理，采购响应回溯、审批等一体化管理的模式',
          ]},
          {time: 2016, content: [
            '挂牌新三板，证券代码：838956',
            '新增参与电力仪器仪表，工器具，辅助设备设施，低压电器等电商化项目的履约',
            '电子发票系统上线，雅诗兰黛、艾默生、春秋航空、震旦、安联保险、通联支付、赫基服饰、芝禾商贸等项目相继上线线'
          ]},
          {time: 2015, content: [
            '助力飞G2上线，整体转型为B/S架构，在原有基础上对功能进行升级，主要包括：商品比价，毛利审批等OA辅助决策功能',
            '完成股份制改革，更名为上海旋荣科技股份有限公司',
            '经销商发票监管软件开发完成正式上线，首批上线的包括长安福特、上汽大众、捷豹路虎等知名汽车厂商',
          ]},
          {time: 2014, content: [
            '参加央企的电商化项目的电商平台的研发',
            '参与央企办公类物资电商化项目的实际业务履约',
          ]},
          {time: 2013, content: [
            '4月8日公司注册成立，名称为上海旋荣实业有限公司',
            '《发票数据管理软件》系列产品研发面世，服务央企，服务中小企业',
            '助力飞第一代（助力飞G1）产品面世，C/S架构，主要用于公司内部管理，以进销存的功能为主',
          ]},
        ],
      }
    }
  },
}
</script>

<style scoped>
.memoir {background-color: #ecf0f5; padding-bottom: 1rem;}

.memoir .title {font-weight: 600; border-bottom: 1px solid #ccc; padding: 1rem 0;}
.memoir .title h1 {font-size: 3rem; color: rgba(82, 0, 0, .9);line-height:1.6; font-weight: 600;}
.memoir-pic {text-align: center; padding: 1rem 0; width: 100%;}
.memoir-main {position: relative;}
.memoir-main .memoir-center-line {position: absolute; height: 100%; top: 0; left: 50%; width: 6px; transform: translateX(-50%); background-color: #999;}
.memoir-list .memoir-odd, .memoir-list .memoir-even {padding: 0 0 3rem;}
.memoir-list .memoit-content {padding: 0 10%;}

.memoir-list .memoir-right {padding: 5rem 0 0;}
.memoir-list .memoir-dian {color: #e67700;float: left; line-height: 1.6;}
.memoir-list .memoir-con {margin-left: 1rem; line-height: 1.6;}
.memoir-list .memoit-time {color: #e67700; font-size: 3rem; line-height: 6rem; position: relative;}
.memoir-list .memoit-time>span {display: inline-block; position: absolute; top: 50%;}
.memoir-list .memoit-time .memoir-line {height: 2px; background-color: #ccc; width: calc(100% - 3em);}
.memoir-list .memoir-odd .memoit-time {margin-left: 10%;}
.memoir-list .memoir-odd .memoir-yuan1 {right: calc(100% - 3em); transform: translate(.2rem, -50%);}
.memoir-list .memoir-odd .memoir-line {right: 0; transform: translateX(-.4rem);}
.memoir-list .memoir-odd .memoir-yuan2 {right: 0; transform: translate(50%, -50%);}
.memoir-list .memoir-even .memoit-time {text-align: right; margin-right: 10%;}
.memoir-list .memoir-even .memoir-yuan1 {left: calc(100% - 3em); transform: translate(-.2rem, -50%);}
.memoir-list .memoir-even .memoir-line {left: 0; transform: translateX(-.2rem);}
.memoir-list .memoir-even .memoir-yuan2 {left: 0; transform: translate(-50%, -50%);}
.memoir-list .memoir-yuan1 {width: .6rem; height: .6rem; border: 1px solid #666;}
.memoir-list .memoir-yuan2 {width: .6rem; height: .6rem; border: .4rem solid #e67700;}
.memoir-list .memoir-yuan1, .memoir-list .memoir-yuan2 {border-radius: 50%; background-color: #fff; z-index: 1;}


</style>