<template>
  <section class="imgbanner">
    <img :src="imgUrl" alt="">
    <el-image v-if="imgBannerObj.slogan" :src="imgBannerObj.slogan" :class="imgBannerObj.class ? 'slogan ' + imgBannerObj.class : 'slogan'"></el-image>
  </section>
</template>

<script>
export default {
  name: "imgBanner",
  props: {
    imgBannerObj: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  data () {
    return {
      imgUrl: require("../assets/images/aboutus/1.png"),
    };
  },
  created () {
    this.imgUrl = this.imgBannerObj && this.imgBannerObj.url ? this.imgBannerObj.url : this.imgUrl;
  }
};
</script>

<style scoped>
/* .imgbanner{max-width:1920px;margin:0 auto;} */
.imgbanner {position: relative;}
.imgbanner img{width:100%; position: relative; overflow: hidden;}
.imgbanner .slogan {height: 6rem; position: absolute; left: 10%; top: 45%; z-index: 1; animation: leftToRight 1s ease-in-out 1s backwards;}
.imgbanner .teamSlogan {height: 3rem; top: 35%; left: 50%; transform: translate(-50%, 0)}
.imgbanner .developSlogan {height: 1.2rem; left: 50%; transform: translate(-50%, 0); width: 70%; top: 70%;}
.imgbanner .profileSlogan {height: 2rem; left: 80%; transform: translate(-50%, 0); top: 32%;}
.imgbanner .investorSlogan {left: 28%; transform: translate(-50%, 0);}
</style>